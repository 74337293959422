import React, { Component } from 'react';
// import Datas from '../data/icon-box/icon-box.json';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/iconBox.js";

const Datas = [
    {
        "id": "1",
        "uniqClass": "box-icon box1",
        "boxIcon": "flaticon-thumbs-like",
        "title": "Admission Enquery",
        "subTitle": "Make Enquiry"
    },
    {
        "id": "2",
        "uniqClass": "box-icon box2",
        "boxIcon": "flaticon-reading",
        "title": "Registration Form",
        "subTitle": "Register Now"
    },
    {
        "id": "3",
        "uniqClass": "box-icon box3",
        "boxIcon": "flaticon-teacher",
        "title": "Careers Information",
        "subTitle": "Apply now."
    }
]

class IconBox extends Component {
    render() {
        return (
            <Styles>
                {/* Icon Box */}
                <section id="timing" className="icon-box-area">
                    <Container>
                        {/* <Row>
                            {
                                Datas.map((data, i) => (
                                    <Col md="4" key={i}>
                                        <div className="full-icon-box  " role='button'>
                                            <div className="icon-box d-flex">
                                                <div className={data.uniqClass}>
                                                    <i className={data.boxIcon}></i>
                                                </div>
                                                <div className="box-title ">
                                                    <h6>{data.title}</h6>
                                                    <p>{data.subTitle}</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row> */}
                        
                        <Row>
                            <Col md="6">
                                <div className="full-icon-box  " role='button'>
                                    <div className="icon-box d-flex">
                                        <div className={"box-icon box2"}>
                                            <i className={"flaticon-teacher"}></i>
                                        </div>
                                        <div className="box-title ">
                                            <h6>School Hours</h6>
                                            <div className='box-contents d-flex'> <div style={{width : "60px"}}  className='fw-bold '>Summer</div>  : 7:45 am to 1:45 pm </div>
                                            <div className='box-contents d-flex'> <div style={{width : "60px"}}  className='fw-bold '>Winter</div>  : 8:30 am to 2:30 pm </div>
                                       
                                        </div>

                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="full-icon-box  " role='button'>
                                    <div className="icon-box d-flex">
                                        <div className={"box-icon box1"}>
                                            <i className={"flaticon-reading"}></i>
                                        </div>
                                        <div className="box-title ">
                                            <h6>Office Hours</h6>
                                            <div className='box-contents d-flex'> <div style={{width : "60px"}}  className='fw-bold '>Summer</div>  : 8:00 am to 1:00 pm </div>
                                            <div className='box-contents d-flex'> <div style={{width : "60px"}}  className='fw-bold '>Winter</div>  : 9:00 am to 2:00 pm </div>
                                       
                                        </div>

                                    </div>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default IconBox
