import React, { Component } from 'react';
// import Datas from '../data/hero/hero-slider.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/heroSlider.js";

const Datas = [
    {
        "id": "1",
        "backgroundImage": "/assets/custom_images/home_slider/new/1.jpeg",
        "uniqClass": "slider-box slider-box1",
        "title": "Welcome to",
        "desc": "ST. MARY'S CONVENT SCHOOL",
        "desc2": "Mariam Nagar, Ghaziabad",
        "btnOneLink": "course-grid",
        "btnTwoLink": "contact"
    },
    {
        "id": "2",
        "backgroundImage": "/assets/custom_images/home_slider/new/4.jpeg",
        "uniqClass": "slider-box slider-box2 text-right",
        "title": "Welcome to",
        "desc": "ST. MARY'S CONVENT SCHOOL",
        "desc2": "Mariam Nagar, Ghaziabad",
        "btnOneLink": "course-grid",
        "btnTwoLink": "contact"
    },
    {
        "id": "3",
        "backgroundImage": "/assets/custom_images/home_slider/new/6.jpeg",
        "uniqClass": "slider-box slider-box1",

        "title": "Welcome to",
        "desc": "ST. MARY'S CONVENT SCHOOL",
        "desc2": "Mariam Nagar, Ghaziabad",
        "btnOneLink": "course-grid",
        "btnTwoLink": "contact"
    },
    {
        "id": "4",
        "backgroundImage": "/assets/custom_images/home_slider/new/5.jpeg",
        "uniqClass": "slider-box slider-box2 text-right",
        "title": "Welcome to",
        "desc": "ST. MARY'S CONVENT SCHOOL",
        "desc2": "Mariam Nagar, Ghaziabad",
        "btnOneLink": "course-grid",
        "btnTwoLink": "contact"
    }
]

class HeroSlider extends Component {
    render() {
        const settings = {
            slidesPerView: 1,
            loop: true,
            speed: 3000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.slider-button-next',
                prevEl: '.slider-button-prev'
            },
            renderPrevButton: () => (
                <div className="swiper-btn slider-button-prev d-none d-lg-block"><i className="flaticon-arrow-left-th"></i></div>
            ),
            renderNextButton: () => (
                <div className="swiper-btn slider-button-next d-none d-lg-block"><i className="flaticon-arrow-right-th"></i></div>
            )
        };

        return (
            <Styles>
                {/* Hero Slider */}
                <section className="hero-slider-area">
                    <Swiper {...settings}>
                        {
                            Datas.map((data, i) => (
                                <div className="slider-item" key={i}>
                                    <div 
                                        // style={{backgroundColor : "black"}}
                                        className="">

                                        <div style={{maxHeight : "80vh"}} className="image-container">
                                            <div 
                                            style={{width : "100%", height : "100%", backgroundColor : "black", zIndex : 10, opacity : "0.1"}}
                                            className="image-overlay">
                                                
                                            </div>
                                            <img src={process.env.PUBLIC_URL + `${data.backgroundImage}`} className="slider-image" alt={data.backgroundImage} />
                                        </div>
                                    </div>
                                    <div className="slider-table">
                                        <div className="slider-tablecell">
                                            <Container>
                                                <Row>
                                                    <Col md="12">
                                                        <div className={data.uniqClass}>
                                                            <div className="slider-title">
                                                                <p>{data.title}</p>
                                                            </div>
                                                            <div className="slider-desc">
                                                                <h1>{data.desc}</h1>
                                                            </div>
                                                            <div className="slider-desc2">
                                                                <p>{data.desc2}</p>
                                                            </div>
                                                            <div className="slider-btn">
                                                                <Link className="slider-btn1" to={process.env.PUBLIC_URL + `/about`}>About us</Link>
                                                                <Link className="slider-btn2" to={process.env.PUBLIC_URL + `/contact`}>Contact Us</Link>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Swiper>
                </section>
            </Styles>
        )
    }
}

export default HeroSlider
