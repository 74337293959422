import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import Footer from 'components/Footer';
import { Styles } from './styles/course.js';
import { colors } from 'components/common/element/elements';

const Rules = () => {


    return (
        <div className="main-wrapper course-page">
            <HeaderTwo />
            <Styles>
                {/* Course Grid */}
                <section className="course-list-area">
                    <div className="payment-area ">
                        <div className="payment-area ">

                            <div id='dicipline' className="_bg-light ">
                                <Container>
                                    <h5 className='payment-h5 pb-5  pt-5 _text-center'> ACTIVITIES </h5>

                                    <ul className='pl-4 payment-ul '>

                                        <li className='pb-2' >
                                            Games and Sports: The school provides all possible facilities to the students to develop their skills and talents in games and sports.
                                        </li>
                                        <li className='pb-2' >
                                            Cultural Activities: Periodical training, round the year, are extended to pupils to promote their aesthetic and effective domains. Speech, Essay Competitions, Debate, Skit, Various forms of dances, music, drawing, etc., are some skill areas where the school takes a keen interest.
                                        </li>
                                        <li className='pb-2' >
                                            Band Troupe: The school has a rigorously trained Band Troupe.
                                        </li>


                                    </ul>
                                    <div className="pt-5">

                                        {/* <hr /> */}
                                    </div>

                                </Container>
                            </div>

                        </div>

                        <div id='leave' className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-5  pt-5 _text-center'> HUMANITARIAN SERVICE </h5>

                                <ul className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                    The service we render to others is really the rent we pay for our room on this earth.
                                    </li>

                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>
                        <div id='leave' className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-5  pt-5 _text-center'> OUT REACH PROGRAMME </h5>

                                <ul className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                    As a part of the Educational Programme, the management, staff, and students together have taken a special step in reaching out to the less privileged ones in the society.
                                    </li>

                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                    </div>

                </section>
            </Styles>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Rules;
