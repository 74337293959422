export const formatDate = (timestamp) => {
  try {
    if (!timestamp || typeof timestamp.seconds !== 'number') {
      throw new Error('Invalid timestamp');
    }
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};

export const formatDateShort = (timestamp) => {
  try {
    if (!timestamp || typeof timestamp.seconds !== 'number') {
      throw new Error('Invalid timestamp');
    }
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString('en-US', {
      // year: 'numeric',
      day: 'numeric',
      month: 'short',
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};


export function truncateString(text = "", n) {
  if (n <= 0) {
    return "";
  }
  return text.slice(0, n);
}