import React, { Component } from 'react';
// import Datas from '../data/footer/footer.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerOne.js";

const Datas = {
    "backgroundImage": "/assets/custom_images/header-logo.png"
}


class Footer extends Component {
    render() {
        return (
            <Styles>
                {/* Footer Area */}
                <footer className="footer1" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + Datas.backgroundImage})` }}>
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="footer-logo-info">
                                    <img style={{ maxWidth: "250px" }} src={process.env.PUBLIC_URL + "/assets/custom_images/header-logo.png"} alt="" className="img-fluid" />
                                    <p>St. Mary's Convent School is run by the Santhome Province of Congregation of the Sisters of Charity. We believe that Education is not just a preparation of life, but life itself.</p>
                                    <ul className="  list-inline ">
                                        <a target='_blank'  rel="noopener noreferrer"  href="https://play.google.com/store/apps/details?id=com.appscook.parentconnect.stmarysmariam">
                                            <li style={{ border: "1px solid white" }} className="list-inline-item p-2 rounded-pill btn btn-secondary text-light">
                                                <i className="lab la-android"></i>
                                                Android app
                                            </li>
                                        </a>
                                        <a target='_blank'  rel="noopener noreferrer"  href="https://apps.apple.com/in/app/st-marys-school-mariam-nagar/id6477323561">
                                            <li style={{ border: "1px solid white" }} className="ml-2 list-inline-item p-2 rounded-pill btn btn-secondary text-light">
                                                <i className="lab la-apple"></i>
                                                iOS app
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="f-links">
                                    <h5>Useful Links</h5>
                                    <ul className="list-unstyled">
                                        <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Home</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/about"}><i className="las la-angle-right"></i>About Us</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/gallery"}><i className="las la-angle-right"></i>Gallery</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/achievements"}><i className="las la-angle-right"></i>Achievements</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/about"}><i className="las la-angle-right"></i>Message</Link></li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li><Link to={process.env.PUBLIC_URL + "/noticeboard"}><i className="las la-angle-right"></i>Notice Board</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/prospectus#prospectus"}><i className="las la-angle-right"></i>Prospectus</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/prospectus#download"}><i className="las la-angle-right"></i>Downloads</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/eventlist"}><i className="las la-angle-right"></i>Events</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/payments"}><i className="las la-angle-right"></i>Online Fees</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="f-post">
                                    <h5>Contact Us</h5>
                                    <div className="footer-logo-info">
                                        <ul className="list-unstyled">
                                            <li><i className="las la-map-marker"></i>St. Mary's Convent School, </li>
                                            {/* <li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mariam Nagar Ghaziabad (U.P.) 201010</li> */}
                                            <li><i className="las la-map-marker"></i>Mariam Nagar Ghaziabad (U.P.) 201003</li>
                                            <li><i className="las la-envelope"></i>stmaryconventschool8@gmail.com</li>
                                            <li><i className="las la-phone"></i>+91  8505960140</li>
                                        </ul>
                                    </div>
                                   
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

                {/* Copyright Area */}
                <section className="copyright-area">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="copy-text">
                                    <p className='d-none d-sm-block'>Copyright &copy; 2024 | Made With <i className="las la-heart"></i> by <a  href="https://soulcodesolutions.com/"  target="_blank" rel="noopener noreferrer">Soulcode solutions</a></p>
                                    <p className='d-block d-sm-none '>Copyright &copy; 2024 <br /> Made With <i className="las la-heart"></i> by <a target="_blank" href="https://soulcodesolutions.com/"   rel="noopener noreferrer">Soulcode solutions</a></p>
                                </div>
                            </Col>
                            <Col md="6" className="text-right">
                                <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a href="https://www.facebook.com/stmary.mariamnagar"><i className="fab fa-facebook-f"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li> */}
                                    <li className="list-inline-item"><a href="https://www.youtube.com/@stmarysconventschoolmariam8739"><i className="fab fa-youtube"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li> */}
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                    {/* Back To Top */}
                    <BackToTop />
                </section>
            </Styles>
        )
    }
}

export default Footer
