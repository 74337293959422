import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/contact.js';
import Footer from '../../components/Footer';
import { db, collection, addDoc} from '../../FirebaseConfig';
// import { collection, addDoc } from 'firebase/firestore';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [formStatus, setFormStatus] = useState({
        isSending: false,
        isSent: false,
        error: '',
        
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
        setFormStatus((prevStatus) => ({
            ...prevStatus,
            [`${id}Error`]: ''
        }));
    };

    const isEmail = (email) => {
        return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    };

    const formSubmit = async (e) => {
        e.preventDefault();

        const { name, email, subject, message } = formData;

        let hasError = false;

        if (name.trim() === "") {
            setFormStatus((prevStatus) => ({
                ...prevStatus,
                error: "Name can't be blank"
            }));
            hasError = true;
        }

        if (email.trim() === "") {
            setFormStatus((prevStatus) => ({
                ...prevStatus,
                error: "Email can't be blank"
            }));
            hasError = true;
        } else if (!isEmail(email.trim())) {
            setFormStatus((prevStatus) => ({
                ...prevStatus,
                error: "Not a valid email"
            }));
            hasError = true;
        }

        if (subject.trim() === "") {
            setFormStatus((prevStatus) => ({
                ...prevStatus,
                error: "Subject can't be blank"
            }));
            hasError = true;
        }

        if (message.trim() === "") {
            setFormStatus((prevStatus) => ({
                ...prevStatus,
                error: "Message can't be blank"
            }));
            hasError = true;
        }

        if (hasError) {
            return;
        }

        setFormStatus({ isSending: true, isSent: false, error: '' });

        try {
            const docRef = await addDoc(collection(db, 'messages'), formData);
            console.log('Document written with ID: ', docRef.id);
            setFormStatus({ isSending: false, isSent: true, error: '' });
        } catch (error) {
            console.error('Error adding document: ', error);
            setFormStatus({ isSending: false, isSent: false, error: 'Error sending message' });
        }
    };

    const isFormDisabled = formStatus.isSending || formStatus.isSent;

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper contact-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Contact Us" />

                {/* Contact Area */}
                <section className="contact-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="contact-box-title">
                                    <h4>Contact Info</h4>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-map-marker"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Our Location</h5>
                                        <p>St. Mary's Convent School, <br/> Mariam Nagar Ghaziabad (U.P.) <br/> 201010</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-envelope-open"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Email Address</h5>
                                        <p>stmaryconventschool8@gmail.com</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-phone"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Phone Number</h5>
                                        <p>(+91) 8505960140</p>
                                    </div>
                                </div>
                                <div className="contact-social">
                                    <ul className="social list-unstyled list-inline _text-center">
                                        <li className="list-inline-item"><a href="https://www.facebook.com/stmary.mariamnagar"><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.youtube.com/@stmarysconventschoolmariam8739"><i className="fab fa-youtube"></i></a></li>
                                        {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li> */}
                                    </ul>
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="contact-form">
                                    <div className="form-title">
                                        <h4>Get In Touch</h4>
                                    </div>
                                    <div className="form-box">
                                        <form id="form_contact" className="form" onSubmit={formSubmit}>
                                            <Row>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input
                                                            type="text"
                                                            placeholder="Full Name"
                                                            id="name"
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            disabled={isFormDisabled}
                                                        />
                                                        <span className="contact_input-msg">{formStatus.nameError}</span>
                                                    </p>
                                                </Col>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input
                                                            type="email"
                                                            placeholder="Email Address"
                                                            id="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            disabled={isFormDisabled}
                                                        />
                                                        <span className="contact_input-msg">{formStatus.emailError}</span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <input
                                                            type="text"
                                                            placeholder="Subject"
                                                            id="subject"
                                                            value={formData.subject}
                                                            onChange={handleInputChange}
                                                            disabled={isFormDisabled}
                                                        />
                                                        <span className="contact_input-msg">{formStatus.subjectError}</span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <textarea
                                                            name="message"
                                                            id="message"
                                                            placeholder="Enter Message"
                                                            value={formData.message}
                                                            onChange={handleInputChange}
                                                            disabled={isFormDisabled}
                                                        ></textarea>
                                                        <span className="contact_input-msg">{formStatus.messageError}</span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    {formStatus.error && <div className="error-message pb-[10px] text-center text-danger">{formStatus.error}</div>}
                                                    <button type="submit" disabled={isFormDisabled}>
                                                        {formStatus.isSending ? 'Sending...' : formStatus.isSent ? 'Message Sent' : 'Send Message'}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <Footer />

            </div>
        </Styles>
    );
}

export default Contact;
