import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import Footer from 'components/Footer';
import { Styles } from './styles/course.js';
import { colors } from 'components/common/element/elements';

const Rules = () => {


    return (
        <div className="main-wrapper course-page">
            <HeaderTwo />
            <Styles>
                {/* Course Grid */}
                <section className="course-list-area">
                    <div className="payment-area ">
                        <div className="payment-area ">

                            <div id='dicipline' className="_bg-light ">
                                <Container>
                                    <h5 className='payment-h5 pb-5  pt-5 _text-center'> Rules of  Conduct and Discipline </h5>

                                    <ul className='pl-4 payment-ul '>

                                        <li className='pb-2' >
                                            Every student of this school is expected to be courteous in talk and behaviour. Discourtesy of any kind will not be tolerated.
                                        </li>
                                        <li className='pb-2' >
                                            The school has the right to suspend or dismiss the students. Irregular attendance or habitual want of the application to one's duty or unbecoming behaviour or conduct injurious to the moral tone of the school or insubordination to authority or any other serious type of misconduct is sufficient reason for the suspension or dismissal of a student.
                                        </li>
                                        <li className='pb-2' >
                                            Any kind of damage done to the school property including ink stains and writing on the wall or the school furniture must be compensated at the expense of the offender along with the fine that may be imposed by the Principal.
                                        </li>
                                        <li className='pb-2' >
                                            It is forbidden to write or scribe on the walls, throw paper or ink in and out of the classroom or in any way to damage the school premises or property. Damage done will have to be made good.
                                        </li>
                                        <li className='pb-2' >
                                            Pupils are themselves responsible for their own books, fountain pens, cycles, tiffin carriers or any other articles they may bring to school.
                                        </li>

                                    </ul>
                                    <div className="pt-5">

                                        {/* <hr /> */}
                                    </div>

                                </Container>
                            </div>

                        </div>

                        <div id='leave' className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-5  pt-5 _text-center'> Withdrawal & Leave </h5>

                                <ul className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                        No student will be granted leave without prior written application of the parents/guardians. In all cases, permission is at the discretion of the Principal. Those who absent themselves for six consecutive days without prior permission from the Principal are liable to have their names struck off the rolls. Re-admission may be granted only after payment of a fresh admission fee.
                                    </li>
                                    <li className='pb-2' >
                                        Pupils should attend the school regularly. They must reach the school at least five minutes before the assembly. Late comers may not be allowed to attend the sessions of the day.
                                    </li>
                                    <li className='pb-2' >
                                        No pupil shall leave the school premises during school hours or recess without the previous permission of the Principal.
                                    </li>
                                    <li className='pb-2' >
                                        Parents or guardians will not be allowed to visit their children during school hours.
                                    </li>
                                    <li className='pb-2' >
                                        Pupils are not allowed to interview parents or visitors or to leave the school premises during school hours without the permission of the Principal.
                                    </li>

                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                    </div>

                </section>
            </Styles>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Rules;
