import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import Footer from 'components/Footer';
import { Styles } from './styles/course.js';
import { colors } from 'components/common/element/elements';

const Payments = () => {
     

    return (
        <div className="main-wrapper course-page">
            <HeaderTwo />
            <Styles>
                {/* Course Grid */}
                <section className="course-list-area">
                    <div className="payment-area ">

                        <div className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-5  pt-5 _text-center'> ADMISSIONS </h5>
                                
                                <ul  className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                        Parents seeking admission of their ward must register the name in advance.
                                    </li>
                                    <li className='pb-2' >
                                        Registration to the classes starts in the month of October.
                                    </li>
                                    <li className='pb-2' >
                                        Registration does not guarantee admission and the validity of the registration is only for 15 days from the date of registration.
                                    </li>
                                    <li className='pb-2' >
                                        No candidate will be admitted without an interview and entrance test.
                                    </li>
                                    <li className='pb-2' >
                                        The selection of the candidate will be done at the discretion of the principal alone and parents/guardians of the candidate or other relations or others will not have any right to question the procedure of selection of students of any class.
                                    </li>
                                    <li className='pb-2' >
                                        Those students who join the school at any time of the current academic year will have to pay the complete fee of that academic year even if they join late.
                                    </li>
                                    <li className='pb-2' >
                                        For selection and admission of any candidate, no request or recommendation will be entertained. In all matters, the principal's decision is final.
                                    </li>
                                    <li className='pb-2' >
                                        A leave letter is a must in the school. If students take leave for 10-15 days without information, their name should be removed from the school register.
                                    </li>
                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                    </div>
                </section>
            </Styles>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Payments;
