// import React from 'react';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, getDocs, query, limit, orderBy, startAfter, doc, getDoc} from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword , signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAruW_QqJ3bxgKYXNXPblP9ELUuaOWi3Ko",
  authDomain: "stmarysmariamnagar.firebaseapp.com",
  projectId: "stmarysmariamnagar",
  storageBucket: "stmarysmariamnagar.appspot.com",
  messagingSenderId: "297583524465",
  appId: "1:297583524465:web:eb3571b8d0c9b14b1939d0",
  measurementId: "G-2WREWN98MN"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, doc, getDoc, collection, addDoc, getDocs, query, limit, orderBy, startAfter, auth, signInWithEmailAndPassword , createUserWithEmailAndPassword , signOut};