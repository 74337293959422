import React, { useEffect, useState } from 'react';
// import Datas from '../data/faq-event/faq-event.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/faqEvent.js";
// import { colors } from './common/element/elements';


import { db, collection, query, limit, orderBy, startAfter, getDocs } from 'FirebaseConfig';
import {   formatDateShort, truncateString } from 'helper/DateFormat';



function FaqEvent() {
    useEffect(() => {
        const accordionButton = document.querySelectorAll(".accordion-button");
        accordionButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "accordion-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "accordion-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });


    const [achievements, setAchievements] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);

    // const [loadingMore, setLoadingMore] = useState(false);
    // const [hasMore, setHasMore] = useState(true);
    // const [noMoreRecords, setNoMoreRecords] = useState(false);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            const achievmentsQuery = query(
                collection(db, 'events'),
                orderBy('ac_date', 'desc'),
                limit(4)
            );
            const querySnapshot = await getDocs(achievmentsQuery);
            const achievementsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAchievements(achievementsData);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching events: ", error);
            setLoading(false);
        }
    };


    return (
        <Styles>
            {/* Faq & Event */}
            {
                achievements.length > 0 &&

            
            <section className="event-faq-area">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="event-area">
                                <Row>
                                    <Col md="12">
                                        <div className="sec-title">
                                            <h4>School  <span>Happenings</span></h4>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <Row    >

                                                {
                                                    achievements.map((eventData, i) => (
                                                        <Col className='pb-4' sm="12" lg="6" key={i}   >
                                                        <div className="event-box d-flex"  >
                                                            <div className="event-date text-center  d-none  d-lg-flex">
                                                                <p className=''>{formatDateShort(eventData.ac_date)} </p>
                                                            </div>
                                                            <div className="event-details">
                                                                <h6 className=' font-bold'> <Link to="/eventlist"> {eventData.title} </Link>  </h6>
                                                                <ul className="list-unstyled list-inline">
                                                                    <li className="list-inline-item"><i className="las la-clock"></i> {formatDateShort(eventData.ac_date)}</li>
                                                                    <li className="list-inline-item"><i className="las la-map-marker"></i>{eventData.location}</li>
                                                                </ul>
                                                                <p style={{ maxWidth: "400px" }} className='d-none d-sm-block'>{truncateString(eventData.description, 60)}... </p>
                                                                <p style={{ maxWidth: "400px" }} className='d-block d-sm-none mobile' >{truncateString(eventData.description, 500)}... </p>
                                                            </div>
                                                        </div>

                                                        </Col>
                                                    ))
                                                }
                                        </Row>

                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        {/* <Col md="6">
                            <div className="faq-area">
                                <div className="sec-title">
                                    <h4>Frequently Ask <span>Question</span></h4>
                                </div>
                                <div className="faq-box">
                                    {
                                        Datas.faqDataList.map((faqData, i) => (
                                            <div className="faq-item" key={i}>
                                                <button className="accordion-button active">
                                                    <div className="accordion-icon"><i className="las la-plus"></i></div>
                                                    <p>{faqData.faqTitle}</p>
                                                </button>
                                                <div className="accordion-content show">
                                                    <p>{faqData.faqDesc}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </section>
            }
        </Styles>
    )
}

export default FaqEvent
