import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import Footer from 'components/Footer';
import { Styles } from './styles/course.js';
import { colors } from 'components/common/element/elements';

const Payments = () => {
     

    return (
        <div className="main-wrapper course-page">
            <HeaderTwo />
            <Styles>
                {/* Course Grid */}
                <section className="course-list-area">
                    <div className="payment-area ">

                        <div className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-5  pt-5 _text-center'> EXAMINATION AND PROMOTION </h5>
                                
                                <ul  className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                    No appeal for promotion of a student declared as having failed or detained will be entertained. All classes where doubts regarding promotions exist are left entirely to the Principal, and her decision is final.
                                    </li>
                                    <li className='pb-2' >
                                    Besides unit tests, there will be a terminal and final examination held in the course of the year.
                                    </li>
                                    <li className='pb-2' >
                                    Perfect order and discipline should be maintained during examinations and tests. Students found guilty of any malpractice will be subjected to stern disciplinary action.
                                    </li>
                                    <li className='pb-2' >
                                    Pupils failing any class for the second time will be asked to leave the school.
                                    </li>
                                    <li className='pb-2' >
                                    A minimum of 40% is required for a pass in each subject.
                                    </li>
                                    <li className='pb-2' >
                                    Results of the examinations are final and not to be reconsidered. The annual examination answer paper will not be shown to anybody.
                                    </li>
                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                    </div>
                </section>
            </Styles>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Payments;
