import React, { useState, useEffect } from 'react';

// import Datas from '../../data/event/events.json';
// import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
// import Pagination from '../../components/Pagination';
// import CourseSidebar from '../courses/components/CourseSidebar';
// import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/event.js';
import Footer from 'components/Footer';

import { db, collection, query, limit, orderBy, startAfter, getDocs } from 'FirebaseConfig';
import { formatDate } from 'helper/DateFormat';


const Events = () => {

    const [achievements, setAchievements] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [noMoreRecords, setNoMoreRecords] = useState(false);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            const achievmentsQuery = query(
                collection(db, 'events'),
                orderBy('ac_date', 'desc'),
                limit(5)
            );
            const querySnapshot = await getDocs(achievmentsQuery);
            const achievementsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAchievements(achievementsData);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching achievements: ", error);
            setLoading(false);
        }
    };

    const fetchMoreData = async () => {
        setLoadingMore(true);
        try {
            const achievmentsQuery = query(
                collection(db, 'events'),
                orderBy('ac_date', 'desc'),
                startAfter(lastVisible),
                limit(5)
            );
            const querySnapshot = await getDocs(achievmentsQuery);
            const newAchievements = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAchievements(prevAchievements => [...prevAchievements, ...newAchievements]);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setLoadingMore(false);
            if (querySnapshot.docs.length < 5) {
                setHasMore(false);
                setNoMoreRecords(true);
            }
        } catch (error) {
            console.error("Error fetching more achievements: ", error);
            setLoadingMore(false);
        }
    };

    // console.log(achievements)

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper event-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Events" />

                {/* Events Area */}

                <section className="event-page-area">
                    <Container>
                        {achievements.length > 0 ?
                            <Row>
                                <Col sm="12">
                                    {
                                        achievements.map((data, i) => (
                                            <div className="event-box" key={i}>
                                                <Row>

                                                    <Col sm="12">
                                                        <div className="event-content">
                                                            <div className="content-box">
                                                                <Row>
                                                                    <Col md="9">
                                                                        <div className="event-title">
                                                                            <h6> {data.title} </h6>
                                                                        </div>
                                                                        <div className="event-time-location">
                                                                            <ul className="list-unstyled list-inline">
                                                                                <li className="list-inline-item"><i className="las la-clock"></i> {formatDate(data.ac_date)}</li>
                                                                                <li className="list-inline-item"><i className="las la-map-marker"></i> {data.location}</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="event-desc">
                                                                            <p>{data.description}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="3" className="text-center">
                                                                        <div className="event-date">
                                                                            <p className=''>{formatDate(data.ac_date)}</p>
                                                                        </div>

                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))
                                    }

                                    <Col md="12" className="text-center">
                                        {loading && <div> Loading... </div>}
                                        {loadingMore && <div> Loading more... </div>}
                                        {!loading && !loadingMore && hasMore && (
                                            <div className="text-center">
                                                <div
                                                    style={{ maxWidth: "100px", margin: "auto" }}
                                                    className="details-btn pointer"
                                                    role="button"
                                                    onClick={fetchMoreData}
                                                >
                                                    Load more
                                                </div>
                                            </div>
                                        )}
                                        {!hasMore && noMoreRecords && (
                                            <div className="text-center">
                                                No more records to load
                                            </div>
                                        )}
                                    </Col>
                                </Col>

                                {/* <Col lg="3" md="0">
                                    <CourseSidebar />
                                </Col> */}

                            </Row>
                            :
                            <div style={{ minHeight: "200px" }} className="pt-5 pb-5 w-100 d-flex justify-content-center" >
                                <div className=" " style={{ opacity: "0.5" }}>
                                    <img style={{ maxWidth: "100px", opacity: "0.5" }} src="/assets/custom_images/achievments/icon_blank.png" />
                                    <div className="text-center pt-3  font-weight-bold">
                                        Nothing to display
                                    </div>
                                </div>
                            </div>
                        }
                    </Container>
                </section>



                {/* Footer 2 */}
                <Footer />

            </div>
        </Styles>
    )
}

export default Events