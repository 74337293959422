import React, { Fragment, useEffect } from 'react';
// import Datas from '../../../data/course/item.json';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { formatDate } from 'helper/DateFormat';
// import Pagination from './../../../components/Pagination';
// import DOMPurify from 'dompurify';
import MarkdownView from 'react-showdown';
import { useHistory } from 'react-router-dom';

const NoticeList = (props) => {

    const { Datas = [] } = props

    const history = useHistory();

    const goToDetails = (id) => {
        history.push(`/noticeboard/${id}`);
    }



    return (
        <Fragment>
            {/* Course Item */}
            {
                Datas.map((data, i) => (
                    <Col md="12" key={i}>
                        <div onClick={() => goToDetails(data?.id)} style={{cursor : "pointer"}} className="course-item d-flex ">
                             
                            <div className="course-content">
                                <h6 className="heading text-left">  {data.title} </h6>
                                {/* <div className="desc text-left">
                                    <MarkdownView
                                        markdown={data.description}
                                        options={{ tables: true, emoji: true }}
                                    />
                                </div> */}

                                {/* {console.log(data)} */}
                                <p className="desc text-left"> Uploaded on: {formatDate(data.ac_date)}</p>
                                 
                            </div>
                        </div>
                    </Col>
                ))
            }



        </Fragment>
    )
}

export default NoticeList
