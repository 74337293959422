import React, {  Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { formatDate } from 'helper/DateFormat';


const CourseItemList = (props) => {

    const {Datas = [] } = props 

        return (
            <Fragment>
                {/* Course Item */}
                {
                    Datas.map((data, i) => (
                        <Col md="12" key={i}>
                            <div className="course-item d-flex">
                                
                                <div className="course-content">
                                    <h6 className="heading text-left"><Link to="/achievements"> {data.title}</Link></h6>
                                    <p className="desc text-left">{data.description}</p>
                                    <p className="desc text-left">{formatDate(data.ac_date)}</p>
                                     
                                </div>
                            </div>
                        </Col>
                    ))
                }
                
               

            </Fragment>
        )
}

export default CourseItemList
